/* Color Variables
    How to use:
    h1 {
    color: $primary-color
    }
*/
$white-color-00: #FFFFFF;
$white-color-01: #F5FAFE;

$dark-color-00: #000000;
$dark-color-01: #484848;
$dark-color-02: #798187;

$blue-color-01: #07192C;
$blue-color-02: #072240;
$blue-color-03: #3D5466;
$blue-color-04: #192939;
$blue-color-05: #90A6D2;
$blue-color-06: #006699;
$blue-color-07: #DFEAF4;
$blue-color-08: #00ABEC;

$pink-color-01: #FD6091;

$green-color-00: #b6eb7a;

$blue-color-hover-01: #11759B;
$blue-color-hover-02: #1F3F4F;
$pink-color-hover-01: #D63E6E;
$pink-color-hover-02: #D63E6D;

$gradient-01:#004777;
$gradient-02:#017EB5;
$gradient-03:#0190CB;