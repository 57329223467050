* {
    box-sizing: border-box;
    margin: 0px;
}

*:focus {
    outline: none;
}

body {
    background: #fafafa;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

input,
textarea {
    margin: 0;
    border: none;
    background-color: transparent;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }
}