   /* 
    COMPONENT ANIMATION
    */
   .lottieAnimation {
      >div {
         @include responsiveTo(tablet) {
            width: 345px !important;
            height: 244px !important;
         }
      }
   }